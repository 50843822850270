import React from "react"

import { Helmet } from "react-helmet"

import { Container, Row, Col } from "react-bootstrap"
import LayoutEs from "../components/layoutEs"

const ContactEs = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "es-section contact-section" }} />
      <LayoutEs>
        <Container className="desc-contain">
          <Row>
            <Col>
              <p className="intro-address">
                Calle Ventosa 28, 28005, Madrid Spain
              </p>
              <p className="desc">
                <span className="desc-subhead">EMAIL:</span>
                <br />
                <a href="mailto:ventosa.madrid@gmail.com">
                  ventosa.madrid@gmail.com
                </a>
              </p>
              <p className="desc">
                <span className="desc-subhead">GOOGLE MAPS:</span>
                <br />
                <a href="https://goo.gl/maps/Dnd45UdFJmuUBbSVA">
                  https://goo.gl/maps/Dnd45UdFJmuUBbSVA
                </a>
              </p>
              <p className="desc">
                <span className="desc-subhead">METRO:</span>
                <br />
                Puerta de Toledo (L5), La Latina (L5), Ópera (L2,5,R)
              </p>
              <p className="desc">
                <span className="desc-subhead">AUTOBUS:</span>
                <br />
                Autobuses: C1, 002, 23, 2360, 2363
              </p>
            </Col>
          </Row>
        </Container>
      </LayoutEs>
    </>
  )
}

export default ContactEs
