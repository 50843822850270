import React from "react"
import PropTypes from "prop-types"
import NavigationEs from "./navigationEs.js"
import FooterEs from "./footerEs.js"

import "../assets/scss/style.scss"

import Seo from "./seo.js"

const LayoutEs = ({ children }) => {
  return (
    <>
      <Seo />
      <NavigationEs />
      <main>{children}</main>
      <FooterEs />
    </>
  )
}

LayoutEs.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutEs
